import {
  amber, grey, green, red, common,
} from '@mui/material/colors';

/* 2A6FAE 2B5690 */
const colors = {
  white: common.white,
  background: grey[50],
  primary: '#2A6FAE',
  primaryDark: '#2B5690',
  primaryContrastText: '#ffffff',
  primarySelected: '#00b1fe40',
  secondary: '#536173',
  secondaryLight: '#75808F',
  secondaryContrastText: '#FFEBEE',
  secondarySelected: '#54617320',
  warning: '#ffcc00',
  danger: '#ff4444',
  positive: green[500],
  medium: amber[700],
  negative: red[500],
  neutral: grey[500],
  geometry: '#3bb2d0',
};

const pieColors = [
  colors.primary,
  colors.secondary,
  colors.warning,
  colors.positive,
  colors.medium,
  colors.negative,
  colors.neutral,
  colors.geometry,
];

export default {
  background: {
    default: colors.background,
  },
  primary: {
    main: colors.primary,
    contrastText: colors.primaryContrastText,
    selected: colors.primarySelected,
    dark: colors.primaryDark,
  },
  secondary: {
    main: colors.secondary,
    light: colors.secondaryLight,
    contrastText: colors.secondarySelected,
    selected: colors.secondarySelected,
  },
  warning: {
    main: colors.warning,
  },
  danger: {
    main: colors.danger,
  },
  charts: {
    primary: colors.primary,
    secondary: colors.secondary,
    warning: colors.warning,
    positive: colors.positive,
    medium: colors.medium,
    negative: colors.negative,
    neutral: colors.neutral,
    geometry: colors.geometry,
  },
  colors,
  pieColors,
};
