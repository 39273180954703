import React from 'react';
import {
  List, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';

import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { useSupervisor } from '../../common/util/permissions';

const MenuItem = ({
  title, link, icon, selected,
}) => (
  <ListItemButton key={link} component={Link} to={link} selected={selected}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItemButton>
);

const OperatorMenu = () => {
  const t = useTranslation();
  const location = useLocation();

  // eslint-disable-next-line no-unused-vars
  const supervisor = useSupervisor();

  return (
    <List>
      <MenuItem
        title={t('reportEquipment')}
        link="/operator/equipment"
        icon={<BuildIcon />}
        selected={location.pathname === '/operator/equipment'}
      />
    </List>
  );
};

export default OperatorMenu;
