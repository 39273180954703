import React from 'react';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LogoImageContrast from './login/LogoImageContrast';

const useStyles = makeStyles(() => ({
  page: {
    flexGrow: 1,
    overflow: 'auto',
  },
  menu: {
    zIndex: 1204,
  },
}));

const LoadingNewVersion = () => {
  const classes = useStyles();
  return (
    <div className={classes.page}>
      <Stack sx={{ height: '100%' }} justifyContent="center" alignItems="center">
        <LogoImageContrast />
        <Box sx={{ width: '100%', px: 8, pb: 4 }}>
          <LinearProgress />
        </Box>
        <Typography color="primary">Finding Updates</Typography>
      </Stack>

    </div>
  );
};

export default LoadingNewVersion;
