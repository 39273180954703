/* eslint-disable no-unused-vars */
import React from 'react';
import {
  AreaPlot,
  BarLabel,
  BarPlot, ChartsAxis, ChartsAxisHighlight, ChartsGrid, ChartsLegend, ChartsTooltip, LineElement, LineHighlightElement, LineHighlightPlot, LinePlot, MarkPlot, PieArc, PieArcLabel, PieArcLabelPlot, PieArcPlot, PiePlot, ResponsiveChartContainer,
} from '@mui/x-charts';

const defaultMargin = { top: 40, right: 20, bottom: 70, left: 50 };

const CustomChart = ({
  dataset = [], series = [], xAxis = [], yAxis = [], chartsXAxis = [], chartsYAxis = [], chartAxisHighLight, margin = defaultMargin, ...props
}) => (
  <ResponsiveChartContainer
    dataset={dataset}
    series={series}
    margin={margin}
    xAxis={xAxis}
    yAxis={yAxis}
    {...props}
  >
    <AreaPlot />
    <BarPlot />
    <LinePlot />
    <MarkPlot />
    {chartsXAxis}
    {chartsYAxis}
    <ChartsTooltip />
    <ChartsLegend
      direction="row"
      position={{ horizontal: 'middle', vertical: 'top' }}
      padding={0}
      itemGap={10}
      itemMarkHeight={20}
    />
    <ChartsGrid horizontal />
    {/* <LineElement /> */}
    {chartAxisHighLight}

    <LineHighlightPlot />
  </ResponsiveChartContainer>
);

export default CustomChart;
