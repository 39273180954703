import React, { useState } from 'react';
/* import moment from 'moment'; */
import {
  AccordionDetails,
  Typography,
  TextField,
  Avatar,
  Box,
  InputAdornment,
  Card,
  Divider,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import EditItemView from './components/EditItemView';
import SelectField from '../common/components/SelectField';
import { useTranslation } from '../common/components/LocalizationProvider';
import { mapIconKey, mapIcons } from '../map/core/preloadImages';

/* import { useAdministrator } from '../common/util/permissions'; */
import SettingsMenu from './components/SettingsMenu';
import productionUnits from '../common/util/productionUnits';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const OperationPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  /* const admin = useAdministrator(); */

  const [item, setItem] = useState();

  const validate = () => item && item.productionUnit && item.productionGoal >= 0 && item.productionTimePeriod >= 1;

  const formatValue = (item, key) => {
    switch (key) {
      case 'name':
        return t(`category${item.name.replace(/^\w/, (c) => c.toUpperCase())}`);
      case 'productionUnit':
        return t(`report${item.productionUnit.replace(/^\w/, (c) => c.toUpperCase())}`);
      case 'productionGoal':
        switch (item.productionUnit) {
          case 'engineHours':
            return `${item.productionGoal} ${t('sharedHours')}`;
          case 'pumpedMaterial':
            return `${item.productionGoal} ${t('sharedCubicMeterAbbreviation')}`;
          default:
            return item[key];
        }
      case 'productionUnitAbbreviation':
        switch (item.productionUnit) {
          case 'engineHours':
            return t('sharedHours');
          case 'pumpedMaterial':
            return t('sharedCubicMeterAbbreviation');
          default:
            return item[key];
        }
      default:
        return item[key];
    }
  };

  return (
    <EditItemView
      endpoint="express/operation/categories"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<SettingsMenu />}
      breadcrumbs={['sharedDevice']}
    >
      {item && (
        <Card className={classes.container}>
          <AccordionDetails className={classes.details}>
            <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
              <Avatar src={mapIcons[mapIconKey(item.name)]} variant="rounded" />
              <Typography variant="h5">{formatValue(item, 'name')}</Typography>
            </Box>
            <Divider />
            <SelectField
              value={item.productionUnit || 'default'}
              emptyValue={null}
              onChange={(event) => setItem({ ...item, productionUnit: event.target.value })}
              data={productionUnits.map((category) => ({
                id: category,
                name: t(`report${category.replace(/^\w/, (c) => c.toUpperCase())}`),
              }))}
              label={t('attributeProductionUnit')}
            />

            <TextField
              value={item.productionGoal || ''}
              onChange={(event) => setItem({ ...item, productionGoal: event.target.value })}
              label={t('attributeProductionGoal')}
              type="number"
              InputProps={
                {
                  inputProps: { min: 0 },
                  endAdornment: <InputAdornment sx={{ ml: 2 }} position="end">{formatValue(item, 'productionUnitAbbreviation')}</InputAdornment>,
                }
              }
              error={item.productionGoal < 0}
            />

            <TextField
              value={item.productionTimePeriod || ''}
              onChange={(event) => setItem({ ...item, productionTimePeriod: event.target.value })}
              label={t('attributeProductionTimePeriod')}
              type="number"
              InputProps={
                {
                  inputProps: { min: 1 },
                  endAdornment: <InputAdornment sx={{ ml: 2 }} position="end">{t('sharedDays')}</InputAdornment>,
                }
              }
              error={item.productionTimePeriod < 1}
            />

          </AccordionDetails>
        </Card>
      )}
    </EditItemView>
  );
};

export default OperationPage;
