/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
import {
  TableRow, TableCell, Grid,
  Box,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
  axisClasses, ChartsAxisHighlight, ChartsXAxis, ChartsYAxis,
} from '@mui/x-charts';
import {
  formatDistance, formatHours, formatMsecToHours, formatNumericHours, formatRoundedNumericHours, formatSpeed, formatTime, formatVolume,
  formatVolumeNumeric,
} from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import { useCatch } from '../reactHelper';
import useReportStyles from './common/useReportStyles';
import { useAttributePreference, usePreference } from '../common/util/preferences';
import { prefixString } from '../common/util/stringUtils';
import VirtualizedTable from './components/VirtualizedTable';
import VirtualizedTableShimmer from '../common/components/VirtualizedTableShimmer';
import CorporateReportFilter from './components/CorporateReportFilter';
import palette from '../common/theme/palette';
import CustomChart from './components/CustomChart';
import { volumeUnitString } from '../common/util/converter';
import CustomDataGrid from './components/CustomDataGrid';
import { getProduction, MaintenanceStatusIcon } from '../common/util/corporateReportUtils';
import TotalCard from './components/TotalCard';

const engineHourColumnsGenerator = (t, p) => ([
  {
    field: 'productionStatus',
    headerName: t('deviceStatus'),
    type: 'string',
    valueGetter: (_value, item) => getProduction(item) / item.goal,
    renderCell: (item) => <MaintenanceStatusIcon maintenance={false} />,
    width: 100,
  },
  {
    field: 'device',
    headerName: t('sharedDevice'),
    type: 'string',
    valueGetter: (_value, row) => row.name.toUpperCase(),
    width: 200,
  },
  {
    field: 'engineHours',
    headerName: t('reportEngineHours'),
    type: 'number',
    valueGetter: (_value, row) => row.hours,
    valueFormatter: (v) => formatRoundedNumericHours(v, t),
    width: 120,
  },
  {
    field: 'spentFuel',
    headerName: t('reportSpentFuel'),
    type: 'number',
    valueGetter: (_value, row) => row.spentFuel,
    valueFormatter: (v) => formatVolume(v, p.volumeUnit, t, 0),
    width: 100,
  },
  {
    field: 'distance',
    headerName: t('sharedDistance'),
    type: 'number',
    valueGetter: (_value, row) => row.totalDistance,
    valueFormatter: (v) => formatDistance(v, p.distanceUnit, t),
    width: 100,
  },
]);
const pumpedMaterialColumnsGenerator = (t, p) => ([
  {
    field: 'productionStatus',
    headerName: t('deviceStatus'),
    type: 'string',
    valueGetter: (_value, item) => getProduction(item) / item.goal,
    renderCell: (item) => <MaintenanceStatusIcon maintenance={false} />,
    width: 100,
  },
  {
    field: 'device',
    headerName: t('sharedDevice'),
    type: 'string',
    valueGetter: (_value, row) => row.name.toUpperCase(),
    width: 200,
  },
  {
    field: 'engineHours',
    headerName: t('reportEngineHours'),
    type: 'number',
    valueGetter: (_value, row) => row.hours,
    valueFormatter: (v) => formatNumericHours(v, t),
    width: 120,
  },
  {
    field: 'spentFuel',
    headerName: t('reportSpentFuel'),
    type: 'number',
    valueGetter: (_value, row) => row.spentFuel,
    valueFormatter: (v) => formatVolume(v, p.volumeUnit, t, 0),
    width: 100,
  },
  {
    field: 'pumpedMaterial',
    headerName: t('reportPumpedMaterial'),
    type: 'number',
    valueGetter: (_value, row) => row.production_value,
    valueFormatter: (v) => `${Math.round(v) || 0} ${t('sharedCubicMeterAbbreviation')}`,
    width: 100,
  },
]);

const top5ColumnsArray = [
  ['branch', 'reportBranch'],
  ['construction', 'reportConstruction'],
  ['project', 'reportProject'],
];

const columnsMap = new Map([...top5ColumnsArray]);

const CorporateStatisticsPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();

  const devices = useSelector((state) => state.devices.items);
  const groups = useSelector((state) => state.groups.items);

  const distanceUnit = useAttributePreference('distanceUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const volumeUnit = useAttributePreference('volumeUnit');
  const hours12 = usePreference('twelveHourFormat');

  const engineHoursColumns = useMemo(() => engineHourColumnsGenerator(t, { volumeUnit, distanceUnit }), [t, volumeUnit, distanceUnit]);
  const pumpedMaterialColumns = useMemo(() => pumpedMaterialColumnsGenerator(t, { volumeUnit, distanceUnit }), [t, volumeUnit, distanceUnit]);

  const [items, setItems] = useState([]);
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const top5Columns = ['branch', 'construction', 'project'];

  const formatValue = (item, key) => {
    switch (key) {
      case 'startTime':
      case 'endTime':
        return formatTime(item[key], 'minutes', hours12);
      case 'duration':
        return formatHours(item[key]);
      case 'engineHours':
      case 'hours':
        return formatNumericHours(item[key], t);
      case 'spentFuel':
      case 'fuel_consumption':
        return formatVolume(item[key], volumeUnit, t, 0);
      case 'pumpedMaterial':
        return `${Math.round(item.production_value) || 0} ${t('sharedCubicMeterAbbreviation')}`;
      case 'distance':
        return formatDistance(item[key], distanceUnit, t);
      case 'type':
        return t(prefixString('event', item[key]));
      case 'attributes':
        switch (item.type) {
          case 'alarm':
            return t(prefixString('alarm', item.attributes.alarm));
          case 'deviceOverspeed':
            return formatSpeed(item.attributes.speed, speedUnit, t);
          case 'deviceFuelIncrease':
            return formatVolume(item.attributes.volume, volumeUnit, t, 0);
          default:
            return '';
        }
      default:
        return item[key];
    }
  };

  const showItems = useMemo(() => items.filter((item) => (category !== '' ? item.category === category : true)), [items, category]);
  const formattedItems = useMemo(() => showItems.map((item) => ({
    ...item,
    name: devices[item.deviceid].name,
    engineHours: parseFloat(formatMsecToHours(parseInt((item.hours || 0), 10))),
    spentFuel: parseInt(formatVolumeNumeric(item.fuel_consumption, volumeUnit), 10),
    totalDistance: ((parseInt(item.distance, 10) || 0)).toFixed(0),
  })), [showItems]);
  const chartItems = useMemo(() => formattedItems.filter((item) => selectedItems.includes(item.deviceid)), [showItems, selectedItems]);

  const filterItems = (list, key, value) => [...list].filter((item) => item[key] === value);
  const sortedItems = (list, key) => [...list].sort((a, b) => (parseInt(b[key], 10) || 0) - (parseInt(a[key], 10) || 0));
  const truncateItems = (list, count) => [...list].slice(0, count);

  const engineHoursDataSeries = useMemo(() => ({
    series: [
      {
        type: 'bar',
        yAxisId: 'engineHours',
        label: t('reportEngineHours'),
        color: palette.primary.main,
        data: sortedItems(chartItems, 'hours').map((item) => parseInt(formatMsecToHours(item.hours), 10) || 0),
        valueFormatter: (v, c) => {
          if (c.dataIndex < 0) return '';
          const { hours } = sortedItems(formattedItems, 'hours')[c.dataIndex];
          return formatNumericHours(hours, t);
        },
      },
    ],
    xAxis: [
      {
        id: 'device',
        data: sortedItems(chartItems, 'hours').map((item) => (devices[item.deviceid]?.name)),
        scaleType: 'band',
        valueFormatter: (v, c) => {
          const name = v?.toUpperCase();
          if (name && c.location === 'tick') {
            return name.length > 10 ? `${name.slice(0, 10)}...` : name;
          }
          return name;
        },
        tickLabelInterval: () => true,
      },
    ],
    yAxis: [
      {
        id: 'engineHours',
        scaleType: 'linear',
      },
    ],
    chartsXAxis: [
      <ChartsXAxis
        key="engineHoursDevice"
        position="bottom"
        axisId="device"
        tickInterval={() => true}
        tickLabelStyle={{
          fontSize: '8px',
          textAnchor: 'end',
          angle: -45,
        }}
        sx={{
          [`& .${axisClasses.label}`]: {
            transform: 'translateY(22px)',
          },
        }}
      />,
    ],
    chartsYAxis: [
      <ChartsYAxis
        key="engineHours"
        label={t('sharedHours').charAt(0).toUpperCase() + t('sharedHours').slice(1)}
        position="left"
        axisId="engineHours"
        tickLabelStyle={{ fontSize: 10 }}
        sx={{
          [`& .${axisClasses.label}`]: {
            transform: 'translateX(-5px)',
          },
        }}
      />,
    ],
    chartAxisHighLight: <ChartsAxisHighlight x="band" y="none" />,
  }), [chartItems]);

  const pumpedMaterialDataSeries = useMemo(() => ({
    series: [
      {
        type: 'bar',
        yAxisId: 'pumpedMaterial',
        label: t('reportPumpedMaterial'),
        color: palette.primary.main,
        data: sortedItems(chartItems, 'production_value').map((item) => Math.round(item.production_value) || 0),
        valueFormatter: (v, c) => {
          if (c.dataIndex < 0) return '';
          const { production_value: productionValue } = sortedItems(formattedItems, 'production_value')[c.dataIndex];
          return (`${Math.round(productionValue) || 0} ${t('sharedCubicMeterAbbreviation')}`);
        },
      },
    ],
    xAxis: [
      {
        id: 'device',
        data: sortedItems(chartItems, 'production_value').map((item) => (devices[item.deviceid]?.name)),
        scaleType: 'band',
        valueFormatter: (v, c) => {
          const name = v?.toUpperCase();
          if (name && c.location === 'tick') {
            return name.length > 10 ? `${name.slice(0, 10)}...` : name;
          }
          return name;
        },
        tickLabelInterval: () => true,
      },
    ],
    yAxis: [
      {
        id: 'pumpedMaterial',
        scaleType: 'linear',
      },
    ],
    chartsXAxis: [
      <ChartsXAxis
        key="pumpedMaterialDevice"
        position="bottom"
        axisId="device"
        tickInterval={() => true}
        tickLabelStyle={{
          fontSize: '8px',
          textAnchor: 'end',
          angle: -45,
        }}
        sx={{
          [`& .${axisClasses.label}`]: {
            transform: 'translateY(22px)',
          },
        }}
      />,
    ],
    chartsYAxis: [
      <ChartsYAxis
        key="pumpedMaterial"
        label={t('sharedHours').charAt(0).toUpperCase() + t('sharedHours').slice(1)}
        position="left"
        axisId="pumpedMaterial"
        tickLabelStyle={{ fontSize: 10 }}
        sx={{
          [`& .${axisClasses.label}`]: {
            transform: 'translateX(-5px)',
          },
        }}
      />,
    ],
    chartAxisHighLight: <ChartsAxisHighlight x="band" y="none" />,
  }), [chartItems]);

  const spentFuelDataSeries = useMemo(() => ({
    series: [
      {
        type: 'bar',
        yAxisId: 'spentFuel',
        label: t('reportSpentFuel'),
        color: palette.primary.main,
        data: sortedItems(chartItems, 'fuel_consumption').map((item) => parseInt(formatVolumeNumeric(item.fuel_consumption, volumeUnit), 10) || 0),
        valueFormatter: (v, c) => {
          if (c.dataIndex < 0) return '';
          const { fuel_consumption: spentFuel } = sortedItems(formattedItems, 'spentFuel')[c.dataIndex];
          return formatVolume(spentFuel, volumeUnit, t);
        },
      },
    ],
    xAxis: [
      {
        id: 'device',
        data: sortedItems(chartItems, 'fuel_consumption').map((item) => (devices[item.deviceid]?.name)),
        scaleType: 'band',
        valueFormatter: (v, c) => {
          const name = v?.toUpperCase();
          if (name && c.location === 'tick') {
            return name.length > 10 ? `${name.slice(0, 10)}...` : name;
          }
          return name;
        },
        tickLabelInterval: () => true,
      },
    ],
    yAxis: [
      {
        id: 'spentFuel',
        scaleType: 'linear',
        min: 0,
      },
    ],
    chartsXAxis: [
      <ChartsXAxis
        key="spentFuelDevice"
        position="bottom"
        axisId="device"
        tickInterval={() => true}
        tickLabelStyle={{
          fontSize: '8px',
          textAnchor: 'end',
          angle: -45,
        }}
        sx={{
          [`& .${axisClasses.label}`]: {
            transform: 'translateY(22px)',
          },
        }}
      />,
    ],
    chartsYAxis: [
      <ChartsYAxis
        key="spentFuel"
        label={(volumeUnitString(volumeUnit, t))}
        position="left"
        axisId="spentFuel"
        tickLabelStyle={{ fontSize: 10 }}
        sx={{
          [`& .${axisClasses.label}`]: {
            transform: 'translateX(-5px)',
          },
        }}
      />,
    ],
    chartAxisHighLight: <ChartsAxisHighlight x="band" y="none" />,
  }), [chartItems]);

  const totalPumpedMaterial = () => Math.round([...showItems].reduce((acc, item) => (item.production_unit === 'pumpedMaterial' ? acc + item.production_value : acc), 0));
  const totalSpentFuel = () => [...showItems].reduce((acc, item) => acc + item.fuel_consumption, 0);
  const totalEngineHours = () => [...showItems].reduce((acc, item) => {
    const hours = parseInt(item.hours, 10);
    if (Number.isNaN(hours)) return acc;
    return hours + acc;
  }, 0);

  const handleSubmit = useCatch(async ({ from, to, branchIds, projectIds, constructionIds }) => {
    setLoading(true);
    let groupIds = [];
    if (constructionIds.length > 0) {
      groupIds = constructionIds;
    } else if (projectIds.length > 0) {
      groupIds = projectIds;
    } else if (branchIds.length > 0) {
      groupIds = branchIds;
    }
    try {
      const query = new URLSearchParams({ from, to, tablename: 'heavy_duty' });
      groupIds.forEach((groupId) => query.append('groupId', groupId));
      const response = await fetch(`/api/express/analytics?${query}`);
      if (response.ok) {
        const data = await response.json();
        setItems(data);
        setSelectedItems(data.map((item) => item.deviceid));
      } else {
        const data = await response.text();
        throw Error(data);
      }
    } finally {
      setLoading(false);
    }
  });

  /* const headers = () => (
    <TableRow className={classes.tableHeader}>
      <TableCell sx={{ px: 0, pl: 2 }}>{t('sharedDevice')}</TableCell>
      {columns.map((key) => (<TableCell key={key} sx={{ px: 1 }}>{t(columnsMap.get(key))}</TableCell>))}
    </TableRow>
  ); */
  /* const rowContent = (_index, row) => (
    <>
      <TableCell width={160} sx={{ pl: 2, py: 0 }}>{devices[row.deviceid].name}</TableCell>
      {columns.map((key) => (
        <TableCell key={key} sx={{ p: 0, px: 1, textAlign: 'start', minWidth: { xs: 100 } }}>
          {formatValue(row, key)}
        </TableCell>
      ))}
    </>
  ); */
  const rowShimmer = (cols) => {
    const callback = () => (
      <VirtualizedTableShimmer columns={cols.length + 1} compact />
    );
    return callback;
  };

  const top5Headers = (key) => {
    const callback = () => (
      <TableRow className={classes.tableHeader}>
        <TableCell sx={{ p: 0, pl: 2 }}>{t('sharedDevice')}</TableCell>
        <TableCell sx={{ p: 0, pl: 2 }}>{t(key)}</TableCell>
        {top5Columns.map((key) => (<TableCell key={key} sx={{ px: 1 }}>{t(columnsMap.get(key))}</TableCell>))}
      </TableRow>
    );
    return callback;
  };
  const top5RowContent = (key, valueGetter) => {
    const callback = (_index, row) => {
      const { deviceid } = row;
      const device = devices[deviceid];
      const construction = groups[device.groupId];
      const project = groups[construction.groupId];
      const branch = groups[project.groupId];
      row.branch = branch.name;
      row.project = project.name;
      row.construction = construction.name;
      row[key] = row[valueGetter];
      return (
        <>
          <TableCell sx={{ px: 2, py: 0, minWidth: { xs: 100 } }}>{devices[deviceid].name}</TableCell>
          <TableCell sx={{ px: 2, py: 0, minWidth: { xs: 100 } }}>{formatValue(row, key)}</TableCell>
          {top5Columns.map((key) => (
            <TableCell key={key} sx={{ p: 0, px: 1, textAlign: 'start', minWidth: { xs: 80 } }}>
              {formatValue(row, key)}
            </TableCell>
          ))}
        </>
      );
    };
    return callback;
  };

  const handleCategoryFilter = (category) => {
    setCategory(category);
  };

  const renderCharts = (category, t) => {
    const isStationary = category === 'stationaryConcretePump';
    const chartData = isStationary
      ? { title: t('reportPumpedMaterial'), series: pumpedMaterialDataSeries }
      : { title: t('reportEngineHours'), series: engineHoursDataSeries };

    const spentFuelData = { title: t('reportSpentFuel'), series: spentFuelDataSeries };

    return (
      <>
        {/* Main Chart */}
        <Grid item container xs direction="column">
          <Grid item>
            <Typography variant="h5">{chartData.title}</Typography>
          </Grid>
          <Grid item xs height={260}>
            <CustomChart {...chartData.series} margin={{ top: 40, right: 20, bottom: 60, left: 50 }} />
          </Grid>
        </Grid>

        {/* SpentFuel Chart */}
        <Grid item container xs direction="column">
          <Grid item>
            <Typography variant="h5">{spentFuelData.title}</Typography>
          </Grid>
          <Grid item xs height={260}>
            <CustomChart {...spentFuelData.series} margin={{ top: 40, right: 20, bottom: 60, left: 50 }} />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle']}>
      <div className={classes.container}>
        <div className={classes.header}>
          <CorporateReportFilter
            handleSubmit={handleSubmit}
            ignoreDevice
            includeGroups={['branch', 'project', 'construction']}
            deviceType="heavy-duty"
            isDaily
            showOnly
            handleFilter={handleCategoryFilter}
          >
            {/* <ColumnSelect columns={columns} setColumns={setColumns} columnsArray={columnsArray} /> */}
          </CorporateReportFilter>
        </div>
        <div className={classes.containerMain}>
          <Grid container sx={{ flex: 1, px: 2 }} spacing={2}>
            {/* LEFT COLUMN */}
            <Grid item container xs={12} md={5} direction="column" spacing={2} sx={{ height: 640 }}>
              {/* TABLA */}
              <Grid item sx={{ width: '100%' }} xs>
                <Box sx={{ height: 492 }}>
                  <CustomDataGrid
                    columns={category === 'stationaryConcretePump' ? pumpedMaterialColumns : engineHoursColumns}
                    rows={formattedItems}
                    checkboxSelection
                    selections={selectedItems}
                    setSelections={setSelectedItems}
                  />
                </Box>
              </Grid>
              {/* TOTALES */}
              <Grid item container columnSpacing={2} direction="row" xs="auto">
                <TotalCard
                  title={`${t('reportTotal')} ${t('reportPumpedMaterial')}`}
                  value={`${totalPumpedMaterial()} ${t('sharedCubicMeterAbbreviation')}`}
                  width={4}
                />
                <TotalCard
                  title={`${t('reportTotal')} ${t('reportSpentFuel')}`}
                  value={formatVolume(totalSpentFuel(), volumeUnit, t, 0)}
                  width={4}
                />
                <TotalCard
                  title={`${t('reportTotal')} ${t('reportEngineHours')}`}
                  value={formatRoundedNumericHours(totalEngineHours(), t)}
                  width={4}
                />
              </Grid>
            </Grid>
            {/* RIGHT COLUMN */}
            <Grid item container xs={12} md={7} direction="column" spacing={2} sx={{ height: 640 }}>
              {renderCharts(category, t)}
            </Grid>
            {/* TOP 5 */}
            <Grid item xs={12} container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{ bgcolor: palette.primary.main, color: palette.primary.contrastText, px: 2, my: 1, borderRadius: 4 }}
                >
                  {`Top 5 ${t('reportPumpedMaterial')}`}
                </Typography>
                <Box sx={{ height: '200px' }}>
                  <VirtualizedTable
                    columns={top5Headers('reportPumpedMaterial')}
                    rowContent={!loading && items.length > 0 ? top5RowContent('pumpedMaterial', 'production_value') : rowShimmer([...Array(5)])}
                    rows={!loading && items.length > 0 ?
                      truncateItems(sortedItems(filterItems(chartItems, 'production_unit', 'pumpedMaterial'), 'production_value'), 5)
                      :
                      [...Array(5)]}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{ bgcolor: palette.primary.main, color: palette.primary.contrastText, px: 2, my: 1, borderRadius: 4 }}
                >
                  {`Top 5 ${t('reportSpentFuel')}`}
                </Typography>
                <Box sx={{ height: '200px' }}>
                  <VirtualizedTable
                    columns={top5Headers('reportSpentFuel')}
                    rowContent={!loading && items.length > 0 ? top5RowContent('spentFuel', 'fuel_consumption') : rowShimmer([...Array(5)])}
                    rows={!loading && items.length > 0 ?
                      truncateItems(sortedItems([...chartItems], 'fuel_consumption'), 5)
                      :
                      [...Array(5)]}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{ bgcolor: palette.primary.main, color: palette.primary.contrastText, px: 2, my: 1, borderRadius: 4 }}
                >
                  {`Top 5 ${t('reportEngineHours')}`}
                </Typography>
                <Box sx={{ height: '200px' }}>
                  <VirtualizedTable
                    columns={top5Headers('reportEngineHours')}
                    rowContent={!loading && items.length > 0 ? top5RowContent('engineHours', 'hours') : rowShimmer([...Array(5)])}
                    rows={!loading && items.length > 0 ?
                      truncateItems(sortedItems([...chartItems], 'hours'), 5)
                      :
                      [...Array(5)]}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </PageLayout>
  );
};

export default CorporateStatisticsPage;
