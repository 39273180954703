/* eslint-disable import/no-extraneous-dependencies */
import 'typeface-roboto';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';
import CacheBuster from 'react-cache-buster';
import store from './store';
import { LocalizationProvider } from './common/components/LocalizationProvider';
import ErrorHandler from './common/components/ErrorHandler';
import theme from './common/theme';
import Navigation from './Navigation';
import preloadImages from './map/core/preloadImages';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import NativeInterface from './common/components/NativeInterface';
import ServerProvider from './ServerProvider';
import ErrorBoundary from './ErrorBoundary';
import packageInfo from '../package.json';
import LoadingNewVersion from './LoadingNewVersion';

preloadImages();

// eslint-disable-next-line no-undef
const root = createRoot(document.getElementById('root'));
const isProduction = process.env.NODE_ENV === 'production';

root.render(
  <CacheBuster
    currentVersion={packageInfo.version}
    isEnabled={isProduction} // If false, the library is disabled.
    isVerboseMode // If true, the library writes verbose logs to console.
    /* loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check. */
    metaFileDirectory="." // If public assets are hosted somewhere other than root on your server.
    loadingComponent={<LoadingNewVersion />}
  >
    <ErrorBoundary>
      <Provider store={store}>
        <LocalizationProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <ServerProvider>
                <BrowserRouter>
                  <Navigation />
                </BrowserRouter>
              </ServerProvider>
              <ErrorHandler />
              <NativeInterface />
            </ThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </Provider>
    </ErrorBoundary>
  </CacheBuster>,
);

serviceWorkerRegistration.register();
