import React from 'react';
import { Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BrandIcon from '../resources/images/brandIcon.svg';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(0),
    padding: theme.spacing(1),
    height: theme.dimensions.brandToolbar,
    backgroundColor: theme.palette.primary.main,
    width: '100%',
  },
}));

const BrandToolbar = () => {
  const classes = useStyles();

  return (
    <Toolbar className={classes.toolbar}>
      <img src={BrandIcon} alt="Brand" style={{ height: '100%' }} />
    </Toolbar>
  );
};

export default BrandToolbar;
