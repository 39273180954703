import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Divider, Typography } from '@mui/material';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import useReportStyles from './common/useReportStyles';
import CustomDataGrid from './components/CustomDataGrid';
import { formatDistance, formatRoundedNumericHours } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useEffectAsync } from '../reactHelper';
import { useAttributePreference } from '../common/util/preferences';
import NavigationBreadCrumbs from './components/NavigationBreadCrumbs';
import CollectionFabGrid from '../settings/components/CollectionFabGrid';

const maintenanceRecordsColumnsGenerator = (t, preferences, handlers) => [
  {
    field: 'name',
    headerName: t('sharedName'),
    type: 'string',
    valueGetter: (_value, row) => row.computedMaintenance.name,
    valueFormatter: (v) => v.toUpperCase(),
    width: 250,
  },
  {
    field: 'date',
    headerName: t('sharedDate'),
    type: 'date',
    valueGetter: (_value, row) => {
      const date = new Date(row.date);
      date.setDate(date.getDate() + 1); // Adjust date to correct the shift
      return date;
    },
    width: 120,
  },
  {
    field: 'lecture',
    headerName: t('sharedLecture'),
    type: 'number',
    valueGetter: (_value, row) => row.lecture,
    width: 128,
    renderCell: ({ row, value }) => {
      switch (row.computedMaintenance.type) {
        case 'hours':
          return formatRoundedNumericHours(value, t);
        case 'distance':
          return formatDistance(value, preferences.distanceUnit, t);
        default:
          return value;
      }
    },
    filterable: false,
  },
  {
    field: 'cost',
    headerName: t('sharedCost'),
    type: 'number',
    valueGetter: (_value, row) => row.cost,
    width: 120,
    valueFormatter: (value) => value.toLocaleString(undefined, { style: 'currency', currency: 'USD' }),
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: t('sharedActions'),
    width: 100,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Edit"
        onClick={() => handlers.editHandler(params.id)}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Delete"
        onClick={() => handlers.deleteHandler(params.id)}
        showInMenu
        disabled
      />,
    ],
  },
];

const DeviceMaintenanceReportPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const devices = useSelector((state) => state.devices.items);

  const distanceUnit = useAttributePreference('distanceUnit');

  const [items, setItems] = useState([]);

  const handlers = {
    editHandler: (id) => {
      navigate(`/reports/maintenances/records/${id}`);
    },
    deleteHandler: async (id) => {
      const response = await fetch(`/api/express/maintenances/records/${id}`, { method: 'DELETE' });
      if (response.ok) {
        setItems(items.filter((item) => item.id !== id));
      } else {
        throw Error(await response.text());
      }
    },
  };

  const maintenanceRecordsColumns = useMemo(() => maintenanceRecordsColumnsGenerator(t, { distanceUnit }, handlers), [t]);

  const totalCost = useMemo(() => items.reduce((acc, item) => acc + item.cost, 0).toLocaleString(undefined, { style: 'currency', currency: 'USD' }), [items]);

  useEffectAsync(async () => {
    const response = await fetch(`/api/express/maintenances/devices/${id}/records`);
    if (response.ok) {
      setItems(await response.json());
    } else {
      throw Error(await response.text());
    }
  }, []);

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle']}>
      <div className={classes.header}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <NavigationBreadCrumbs
            urls={['/reports/maintenances', `/reports/maintenances/device/${id}`]}
            labels={[t('reportMaintenances'), devices[id] ? devices[id].name : '']}
          />
          <Typography variant="h6">
            {`${totalCost}`}
          </Typography>
        </Box>
      </div>
      <Divider />
      <div className={classes.tableContainer}>
        <CustomDataGrid
          columns={maintenanceRecordsColumns}
          rows={items || []}
          disableColumnFilter={false}
          rowHeight={40}
        />
      </div>
      <CollectionFabGrid editPath={`/reports/maintenances/records/device/${id}`} allow />
    </PageLayout>
  );
};

export default DeviceMaintenanceReportPage;
