import React, { useState } from 'react';
import {
  useMediaQuery, InputLabel, Select, MenuItem, FormControl, Button, TextField, Snackbar, IconButton,
  Typography, Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { sessionActions } from '../store';
import { useLocalization, useTranslation } from '../common/components/LocalizationProvider';
import LoginLayout from './LoginLayout';
import usePersistedState from '../common/util/usePersistedState';
import LogoImageContrast from './LogoImageContrast';
/* import { useCatch } from '../reactHelper'; */
import { matchIsNumeric } from '../common/util/stringUtils';

const useStyles = makeStyles((theme) => ({
  options: {
    position: 'fixed',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  extraContainer: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: theme.spacing(3),
    fontWeight: 500,
    marginLeft: theme.spacing(0),
    textTransform: 'uppercase',
  },

}));

const OperatorLoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const t = useTranslation();

  const { languages, language, setLanguage } = useLocalization();
  const languageList = Object.entries(languages).map((values) => ({ code: values[0], name: values[1].name }));

  const [failed, setFailed] = useState(false);

  const [document, setDocument] = usePersistedState('loginDocument', '');
  const [passkey, setPasskey] = useState('');

  const languageEnabled = useSelector((state) => !state.session.server.attributes['ui.disableLoginLanguage']);

  const [announcementShown, setAnnouncementShown] = useState(false);
  const announcement = useSelector((state) => state.session.server.announcement);

  const handlePasswordLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('/api/express/operators/session', {
        method: 'POST',
        body: new URLSearchParams(`uniqueId=${encodeURIComponent(document)}&passkey=${encodeURIComponent(passkey)}`),
      });
      if (response.ok) {
        const user = await response.json();
        setFailed(false);
        dispatch(sessionActions.updateUser(user));
        navigate('/operator');
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      setFailed(true);
      setPasskey('');
    }
  };

  const handleSpecialKey = (e) => {
    if (e.keyCode === 13 && document) {
      handlePasswordLogin(e);
    }
  };

  /* REVISAR PARA QUÉ SIRVE */

  return (
    <LoginLayout>

      <div className={classes.container}>
        {useMediaQuery(theme.breakpoints.down('lg')) && <LogoImageContrast color={theme.palette.primary.main} />}
        <div className={classes.header}>
          <Typography className={classes.title} color="primary">
            {t('loginOperatorTitle')}
          </Typography>
        </div>
        <TextField
          required
          error={failed}
          label={t('userDocument')}
          name="document"
          value={document}
          autoComplete="document"
          autoFocus={!document}
          onChange={(e) => setDocument(e.target.value)}
          onKeyUp={handleSpecialKey}
          helperText={failed && 'Invalid document'}
        />
        <Box>
          <InputLabel>{t('driverPasskey')}</InputLabel>
          <MuiOtpInput
            value={passkey || ''}
            onChange={(value) => setPasskey(value)}
            TextFieldsProps={{ placeholder: '-', type: 'password', autoComplete: 'new-password' }}
            validateChar={matchIsNumeric}
          />
        </Box>
        <Button
          onClick={handlePasswordLogin}
          onKeyUp={handleSpecialKey}
          variant="contained"
          color="primary"
          disabled={!document || passkey.length < 4}
        >
          {t('loginLogin')}
        </Button>
        <div className={classes.extraContainer}>
          {languageEnabled && (
            <FormControl fullWidth>
              <InputLabel>{t('loginLanguage')}</InputLabel>
              <Select label={t('loginLanguage')} value={language} onChange={(e) => setLanguage(e.target.value)}>
                {languageList.map((it) => <MenuItem key={it.code} value={it.code}>{it.name}</MenuItem>)}
              </Select>
            </FormControl>
          )}
        </div>
        <Button
          onClick={() => navigate('/login')}
          variant="outlined"
          color="secondary"
        >
          {t('loginAdminTitle')}
        </Button>

      </div>
      <Snackbar
        open={!!announcement && !announcementShown}
        message={announcement}
        action={(
          <IconButton size="small" color="inherit" onClick={() => setAnnouncementShown(true)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      />
    </LoginLayout>
  );
};

export default OperatorLoginPage;
