import React, { useEffect, useState } from 'react';
import {
  Button, Dialog, DialogActions,
  DialogContent, DialogTitle, Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from './LocalizationProvider';

const InmersiveAnnouncement = () => {
  const t = useTranslation();
  const [announcementShown, setAnnouncementShown] = useState(false);
  const announcement = useSelector((state) => state.session.server.announcement);
  const announcementDuration = useSelector((state) => state.session.server.attributes.announcementDuration) || 0;
  const announcementTitle = useSelector((state) => state.session.server.attributes.announcementTitle) || t('sharedAnnouncement');

  const [timeLeft, setTimeLeft] = useState(announcementDuration);

  useEffect(() => {
    if (timeLeft > 0) {
      const interval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [timeLeft]);

  return (
    <Dialog
      open={!!announcement && !announcementShown}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setAnnouncementShown(false);
        }
      }}
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      slotProps={{
        backdrop: {
          style: { backgroundColor: 'rgba(0, 0, 0, 0.5)', backdropFilter: 'blur(4px)' },
        },
      }}
    >
      <DialogTitle>
        {announcementTitle}
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: announcement,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setAnnouncementShown(true)}
          color="primary"
          variant="contained"
          disabled={timeLeft > 0}
          sx={{ minWidth: 120 }}
        >
          {timeLeft > 0 ? `${t('sharedAgree')} (${timeLeft})` : t('sharedAgree')}

        </Button>
      </DialogActions>
    </Dialog>

  );
};

export default InmersiveAnnouncement;
