import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  CardActions,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Skeleton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  card: {
    pointerEvents: 'auto',
    width: theme.dimensions.popupMaxWidth,
  },
  media: {
    height: theme.dimensions.popupImageHeight,
  },
  mediaButton: {
    color: theme.palette.colors.white,
    mixBlendMode: 'difference',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 0, 2),
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  table: {
    '& .MuiTableCell-sizeSmall': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  cell: {
    borderBottom: 'none',
  },
  actions: {
    justifyContent: 'space-between',
  },

}));

const StatusRow = ({ name, content }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.cell}>
        <Typography variant="body2">{name}</Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body2" color="textSecondary">{content}</Typography>
      </TableCell>
    </TableRow>
  );
};

const HeavyDutyCardShimmer = ({ desktopPadding = 0, columns }) => {
  const classes = useStyles({ desktopPadding });

  return (

    <Card elevation={3} className={classes.card}>

      <CardMedia className={classes.media}>
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </CardMedia>

      <div className={classes.header}>
        <Typography variant="body2" color="textSecondary" sx={{ width: 100 }}>
          <Skeleton sx={{ width: '100%' }} />
        </Typography>
      </div>

      <CardContent className={classes.content}>
        <Table size="small" classes={{ root: classes.table }}>
          <TableBody>
            {columns.map((key) => (
              <StatusRow
                key={key}
                name={<Skeleton />}
              />
            ))}
          </TableBody>
        </Table>
      </CardContent>

      <CardActions classes={{ root: classes.actions }} disableSpacing />
    </Card>
  );
};

export default HeavyDutyCardShimmer;
