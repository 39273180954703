/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Grid, Accordion, AccordionSummary, AccordionDetails,
  Box,
  AvatarGroup,
  Avatar,
  Typography,
  Stack,
  TextField,
  Button,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useMeasure } from 'react-use';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { makeStyles } from '@mui/styles';
import {
  formatDistance, formatHours, formatNumericHours, formatSpeed, formatTime, formatVolume,
} from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import OperatorMenu from './components/OperatorMenu';
import OperatorFilter from './components/OperatorFilter';
import usePersistedState from '../common/util/usePersistedState';
import { useCatch, useEffectAsync } from '../reactHelper';
import useOperatorStyles from './common/useOperatorStyles';
import { useAttributePreference, usePreference } from '../common/util/preferences';
import HeavyDutyCard from '../common/components/HeavyDutyCard';
import HeavyDutyCardShimmer from '../common/components/HeavyDutyCardShimmer';
import { prefixString } from '../common/util/stringUtils';
import theme from '../common/theme';
import { devicesActions } from '../store';

const useStyles = makeStyles((theme) => ({
  gridItem: {
    position: 'relative',
  },
  gridItemNotifications: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
  },
}));

const columnsArray = [
  ['hours', 'reportEngineHours'],
  ['fuel_consumption', 'reportSpentFuel'],
  ['distance', 'sharedDistance'],
  ['totalDistance', 'sharedDistance'],
  ['alarmHighRpm', 'alarmHighRpm'],
  ['alarmTemperature', 'alarmTemperature'],
  ['alarmHighOilPressure', 'alarmHighOilPressure'],
];
const columnsMap = new Map(columnsArray);

const OperatorDevicePage = () => {
  const classes = useOperatorStyles();
  const componentClasses = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();

  const devices = useSelector((state) => state.devices.items);
  const distanceUnit = useAttributePreference('distanceUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const volumeUnit = useAttributePreference('volumeUnit');
  const hours12 = usePreference('twelveHourFormat');

  const [columns, setColumns] = usePersistedState('operatorColumns', ['hours', 'totalDistance']);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedDevice, setSelectedDevice] = useState(null);

  const [openDetails, setOpenDetails] = useState(false);

  const [updateForm, setUpdateForm] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  useEffectAsync(async () => {
    setLoading(true);
    const response = await fetch('/api/express/operators/devices');
    if (response.ok) {
      const equipments = await response.json();
      setItems(equipments);
    } else {
      throw Error(await response.text());
    }
    setLoading(false);
  }, []);

  const handleOpenDetails = (e) => {
    if (selectedDevice) {
      setOpenDetails((prev) => !prev);
    }
  };

  const formatValue = (item, key) => {
    switch (key) {
      case 'startTime':
      case 'endTime':
        return formatTime(item[key], 'minutes', hours12);
      case 'duration':
        return formatHours(item[key]);
      case 'hours':
        return formatNumericHours(item[key], t);
      case 'fuel_consumption':
        return formatVolume(item[key], volumeUnit, t);
      case 'distance':
        return formatDistance(item[key], distanceUnit, t);
      case 'type':
        return t(prefixString('event', item[key]));
      case 'attributes':
        switch (item.type) {
          case 'alarm':
            return t(prefixString('alarm', item.attributes.alarm));
          case 'deviceOverspeed':
            return formatSpeed(item.attributes.speed, speedUnit, t);
          case 'deviceFuelIncrease':
            return formatVolume(item.attributes.volume, volumeUnit, t);
          default:
            return '';
        }
      case 'productionUnitAbbreviation':
        switch (item.productionUnit) {
          case 'engineHours':
            return t('sharedHours');
          case 'pumpedMaterial':
            return t('sharedCubicMeterAbbreviation');
          default:
            return item[key];
        }
      default:
        return item[key];
    }
  };

  const handleSubmit = useCatch(async ({ deviceIds }) => {
    setLoading(true);
    try {
      const query = new URLSearchParams({});
      deviceIds.forEach((deviceId) => query.append('deviceId', deviceId));
      const response = await fetch(`/api/express/operators/devices?${query.toString()}`);
      if (response.ok) {
        const data = await response.json();
        setItems(data);
      } else {
        const data = await response.text();
        throw Error(data);
      }
    } finally {
      setLoading(false);
    }
  });

  const refreshDevices = async () => {
    const response = await fetch('/api/express/operators/devices');
    if (response.ok) {
      const devices = await response.json();
      dispatch(devicesActions.refresh(devices));
      setItems(devices);
    } else {
      throw Error(await response.text());
    }
  };

  const handleSave = useCatch(async () => {
    setIsLoading(true);
    const response = await fetch('/api/express/operators/productions_queue', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...updateForm, deviceId: selectedDevice.id }),
    });

    if (response.ok) {
      const data = await response.json();
      setUpdateForm({});
      setSelectedDevice(null);
      await refreshDevices();
      setIsLoading(false);
    } else {
      setIsLoading(false);
      throw Error(await response.text());
    }
  });

  const validate = () => (!updateForm.value || updateForm.value < 0);

  const [layoutRef, { width: layoutWidth }] = useMeasure();
  const [gridRef, { width: gridWidth }] = useMeasure();

  const columnsPerRow = () => {
    const paddingSpacing = 2 * parseInt(theme.spacing(2), 10);
    const gapSpacing = parseInt(theme.spacing(2), 10);
    const cardWidth = theme.dimensions.popupMaxWidth;
    const baseColumns = Math.floor((gridWidth - paddingSpacing) / cardWidth);
    const gapedWidth = (baseColumns * cardWidth) + (gapSpacing * (baseColumns - 1));
    return gapedWidth < gridWidth ? baseColumns : baseColumns - 1;
  };

  const computeMissingColumns = () => {
    const totalItems = items.length;
    const columns = columnsPerRow();
    const missingColumns = totalItems % columns;
    return missingColumns === 0 ? 0 : columns - missingColumns;
  };

  return (
    <PageLayout menu={<OperatorMenu />} breadcrumbs={['sharedOperator']} noBackNavigation>
      <div className={classes.container} ref={layoutRef}>
        <div className={classes.header}>
          <OperatorFilter handleSubmit={handleSubmit} multiDevice ignoreDate showOnly />
        </div>
        <Grid
          columns={columnsPerRow()}
          container
          columnGap={2}
          rowGap={2}
          padding={2}
          sx={{ pb: 8 }}
          justifyContent={items.length < columnsPerRow() ? 'flex-start' : 'center'}
          ref={gridRef}
        >
          {
            loading && items.length === 0 && (
              [...Array(5)].map((item, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item xs="auto" key={idx}>
                  <HeavyDutyCardShimmer columns={columns} />
                </Grid>
              )))
          }

          {items.map((device) => (
            <Grid item xs="auto" key={device.id} className={componentClasses.gridItem}>
              {
                loading ?
                  <HeavyDutyCardShimmer columns={columns} />
                  : (
                    <>
                      <HeavyDutyCard
                        deviceId={device.id}
                        columns={columns}
                        position={device.position}
                        columnsMap={columnsMap}
                        selectable={device.needsProduction}
                        selectAction={setSelectedDevice}
                        selected={selectedDevice?.id === device.id}
                      />
                      <AvatarGroup className={componentClasses.gridItemNotifications}>
                        {device.needsProduction && (
                          <Avatar
                            sx={{ bgcolor: theme.palette.warning.main, height: 24, width: 24, p: theme.spacing(0.5) }}
                          >
                            <PriorityHighIcon sx={{ fill: 'black' }} />
                          </Avatar>
                        )}
                      </AvatarGroup>
                    </>
                  )
              }
            </Grid>
          ))}
          {computeMissingColumns() > 0 && [...Array(computeMissingColumns())].map((item, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item xs="auto" key={idx}>
              <Box sx={{ width: theme.dimensions.popupMaxWidth, height: 2 }} />
            </Grid>
          ))}
        </Grid>
        <Accordion
          expanded={openDetails && selectedDevice !== null}
          onChange={handleOpenDetails}
          sx={{
            position: 'fixed',
            bottom: 0,
            width: layoutWidth,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{selectedDevice?.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {selectedDevice && (
              <Grid
                columns={columnsPerRow()}
                container
                columnGap={2}
                rowGap={2}
                padding={0}
                justifyContent={columnsPerRow() > 1 ? 'flex-start' : 'center'}
              >
                <Grid item xs="auto">
                  <TextField
                    sx={{ width: theme.dimensions.popupMaxWidth }}
                    variant="outlined"
                    label={t('reportProductionValue')}
                    type="number"
                    value={updateForm.value || ''}
                    onChange={(e) => setUpdateForm({ ...updateForm, value: e.target.value })}
                    InputProps={
                      {
                        inputProps: { min: 0 },
                        endAdornment: <InputAdornment sx={{ ml: 2 }} position="end">{formatValue(selectedDevice, 'productionUnitAbbreviation')}</InputAdornment>,
                      }
                    }
                    error={updateForm.value < 0}
                  />
                </Grid>
                <Grid item xs="auto">
                  <Box sx={{ position: 'relative' }}>
                    <Button
                      sx={{ width: theme.dimensions.popupMaxWidth }}
                      color="primary"
                      variant="contained"
                      disabled={!updateForm || validate() || isLoading}
                      onClick={handleSave}
                    >
                      {t('sharedSave')}
                    </Button>
                    {isLoading && (
                      <CircularProgress
                        size={24}
                        color="primary"
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    </PageLayout>
  );
};

export default OperatorDevicePage;
